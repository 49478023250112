import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Stack, styled, Card, Divider, Box } from "@mui/material";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import AnchorOutlinedIcon from "@mui/icons-material/AnchorOutlined";
//import { grey } from "@mui/material/colors";
//import moment from "moment";
import { Typography } from 'core';
import { formatNumberToLocaleString } from 'lib/utils';
import { selectAuthUserId, selectAuthUserDetails } from 'services/auth/authSlice';
//import { useOrgLeaderboardQuery } from "services/orgApi";
//---------------------------------------------------------

const StyledAnalyticsCard = styled(Box)(({ theme, highlight }) => ({
  display: "flex", flexDirection: "column", flexGrow: "1",
  alignItems: "center", justifyContent: "center",
  backgroundColor: highlight ? "#f5f5f5" : theme.palette.background.paper,
  width: "15rem", height: "9rem", gap: "0.5rem",
  border: "1px solid #e6e7e9"
  // border: highlight && `3px solid ${theme.palette.background.default}`,
  // boxShadow: theme.shadows[1],
}));
const StyledAnalyticsHeader = styled(Stack)(({ theme }) => ({
  padding: "0.5rem",
  backgroundColor: theme.palette.primary.lighter,
}));

const AnalyticsNumbers = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    fontSize: "20px",
  },
}));
//---------------------------------------------------------

function AnalyticsCard({ theme, userData, selfLeadData, leaderboardUserData, title }) {
  // let orgId = useSelector(selectUserOrgId);
  const userId = useSelector(selectAuthUserId);
  const userDetail = useSelector(selectAuthUserDetails);
  // const [selGroupId, setSelGroupId] = useState(SA_DEF_ORG_GROUP_ID);
  const [leaderBoardData, setLeaderBoardData] = useState({});
  let isUserLeader, thisWeekUserRank = 0, thisWeekUserEngs = 0, thisWeekUserPoints = 0;
  if (leaderboardUserData?.leaderboard) {
    isUserLeader = leaderboardUserData?.leaderboard.filter(ldr => ldr?.user?.id === userId);
    isUserLeader = isUserLeader.length > 0;
  }//console.log('leaderboardUserDataAC_'+isUserLeader, {leaderboardUserData, selfLeadData});
  //let rank, shareCount, totalPoints;

  if (isUserLeader) {
    thisWeekUserRank = leaderBoardData?.rank || 0;
    thisWeekUserEngs = leaderBoardData?.engagements || 0;
    thisWeekUserPoints = leaderBoardData?.points || 0;
  } else if (selfLeadData?.self?.length) {
    const selfEngData = selfLeadData.self[0];
    thisWeekUserRank = 0;//selfEngData?.rank||0;
    thisWeekUserEngs = selfEngData?.engagements || 0;
    thisWeekUserPoints = selfEngData?.points || 0;
  }
  // const { 
  //   data: leaderboardUserData, refetch 
  // } = useOrgLeaderboardQuery({
  //    orgId:orgId,
  //    group_id: selGroupId,
  //    startDate:moment().subtract(6,"days").format("YYYY-MM-DD"),
  //    endDate:moment().format("YYYY-MM-DD"),
  //    source_snetworkplatform_id:"a1R3BYmO",
  //    destination_snetworkplatform_id:"a1R3BYmO"
  // });

  useEffect(() => {
    if (leaderboardUserData) {
      leaderboardUserData?.leaderboard?.map((data) => {
        if (data.user.id === userId) {
          setLeaderBoardData(data);
        }
      })
    }
  }, [leaderboardUserData]);
  /*if(leaderBoardData && userDetail) {
    console.log('leaderBoardData', leaderBoardData);
    console.log('userDetail', userDetail);
  }*/
  //---------------------------------------------------------

  const sumIconSx = { height: 30, width: 30, color: theme.palette.secondary.dark };
  //console.log('userDataAC', userData);
  /*const title = userData ?
    "Hello " + userData.first_name + "! Here's how you fared this week." :
    '';*/

  return (
    <>
      <StyledAnalyticsHeader>
        <Typography size="medium" color_variant="white">
          {title}
        </Typography>
      </StyledAnalyticsHeader>
      <Stack direction="row">
        <StyledAnalyticsCard>
          <BookmarkBorderOutlinedIcon sx={sumIconSx} />
          <AnalyticsNumbers size="xlarge" fontWeight={600}>
            {formatNumberToLocaleString(thisWeekUserRank)}
          </AnalyticsNumbers>
          <Typography size="small" color_variant="lightGrey">
            Rank this week
          </Typography>
        </StyledAnalyticsCard>
        <StyledAnalyticsCard >
          <ShareOutlinedIcon sx={sumIconSx} />
          <AnalyticsNumbers size="xlarge" fontWeight={600}>
            {formatNumberToLocaleString(thisWeekUserEngs)}
          </AnalyticsNumbers>
          <Typography size="small" color_variant="light">
            Shares this week
          </Typography>
        </StyledAnalyticsCard>
        <StyledAnalyticsCard>
          <AnchorOutlinedIcon sx={sumIconSx} />
          <AnalyticsNumbers size="xlarge" fontWeight={600}>
            {formatNumberToLocaleString(thisWeekUserPoints)}
          </AnalyticsNumbers>
          <Typography size="small" color_variant="light">
            {formatNumberToLocaleString(thisWeekUserPoints) > 1 ? "Total Coins" : "Total Coin"}
          </Typography>
        </StyledAnalyticsCard>
      </Stack>
    </>
  );
}

export default AnalyticsCard;
