import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { Grid, styled, useTheme, Box } from "@mui/material";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { SA_DEF_ORG_GROUP_ID } from 'config/app.config';
import { fGetRefDates } from 'lib/appFx';
import { validateObj } from 'lib/utils';
import { PageContainer } from 'core';
import {
  AnalyticsCard, FeedMenuList, Leaderboard,
  ReferAndEarnCard, SubmitIdeaCard, TeleHealthAndPersonCare
} from 'sections';
import { selectAuthUserId, selectUserOrgId } from 'services/auth/authSlice';
import { useOrgLeaderboardQuery, useSelfLeaderboardQuery } from 'services/orgApi';
import { useUserDetailsByIdQuery } from 'services/userApi';
import {
  getFeedListMetaData, getUserSubmittedFeed,
  useOrgFeedQuery
} from 'services/orgFeedApi';
import LoaderPage from 'pages/LoaderPage';
import FeedList from "sections/home/feeds/FeedList";
import GetCareBanner from "sections/home/GetCareBanner";
import BookAppointmentBanner from "sections/home/BookAppointmentBanner";
import UsersFeedList from "sections/home/feeds/UsersFeedList";
import SMediaWidget from "sections/widgets/sMediaWidget/SMediaWidget";
//import SocialMediaConnectModal from "sections/widgets/sMediaWidget/SocialMediaConnectModal";
//---------------------------------------------------------

const StyledAnalyticssliderCard = styled(Box)(({ theme, highlight }) => ({
  flexDirection: "column",
  display: "flex",
  flexWrap: "wrap",
  alignItems: "center",
  flexGrow: "1",
  justifyContent: "center",
  backgroundColor: highlight ? "#F5F5F5" : theme.palette.background.paper,
  width: "220px",
  height: "236px",
  marginLeft: "auto",
  marginRight: "auto",
  gap: "0.5rem",
  borderRadius: "8px",
  border: highlight && `3px solid ${theme.palette.background.default}`,
  boxShadow: theme.shadows[1],
  [theme.breakpoints.down("md")]: {
    marginTop: "0rem",
    width: "300px",
  },
  [theme.breakpoints.down("lg")]: {
    marginLeft: "0",
  },
}));

const StyledCenterGrid = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

const settings = {
  dots: false,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  speed: 1000,
  autoplaySpeed: 5000,
  adaptiveHeight: true,
};
//---------------------------------------------------------

export default function Home({ title }) {
  const [isFetchingData, setIsFetchingData] = useState(true);
  const [paginationData, setPaginationData] = useState({});
  const [feedData, setFeedData] = useState([]);
  const [show, setShow] = useState(false);
  const [feedQuery, setFeedQuery] = useState(skipToken);

  const handleShow = () => {
    if (typeof window !== "undefined") {
      if (window.pageYOffset > 1270) {
        if (!show) {
          setShow(true);
        }
      }
      if (window.pageYOffset < 1270) {
        setShow(false);
      }
    }
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", handleShow);
    }
    return () => {
      if (typeof window !== "undefined") {
        window.removeEventListener("scroll", handleShow);
      }
    };
  }, []);

  let orgId = useSelector(selectUserOrgId);
  const [selGroupId, setSelGroupId] = useState(SA_DEF_ORG_GROUP_ID);

  const sADateRefs = fGetRefDates(); //console.log('fGetRefDates', sADateRefs);
  const { data: leaderboardUserData, refetch: refetchLeaderboardUserData } = useOrgLeaderboardQuery({
    orgId: orgId,
    group_id: selGroupId,
    startDate: sADateRefs.dateAWeekAgo,//moment().subtract(6, "days").format("YYYY-MM-DD"),
    endDate: sADateRefs.dateTomorrow,//moment().format("YYYY-MM-DD"),
    //source_snetworkplatform_id: null,
    //destination_snetworkplatform_id: null,
  }, { skip: !orgId });

  const {
    data: selfLeadApiData, refetch: fRefetchSelfLead,
    isLoading: selfLeadWait, isFetching: selfLeadWait2,
    isError: selfLeadErr, isSuccess: selfLeadSuccessApi,
  } = useSelfLeaderboardQuery(
    {
      orgId: orgId,
      //group_id: stSelGroupId,
      startDate: sADateRefs.dateAWeekAgo,
      endDate: sADateRefs.dateTomorrow,
      /*filter_json: {//"source_snetworkplatform_id":"a1R3BYmO","destination_snetworkplatform_id":"a1R3BYmO"} */
    }, { skip: !orgId, }//refetchOnMountOrArgChange: true }
  );
  //selfLeadApiData && console.log('selfLeadApiData', selfLeadApiData);

  const [page, setPage] = useState(1);
  const [highlightPosts, setHighlightPosts] = useState([]);
  const [initialiLoad, setInitialiLoad] = useState(true);
  const [getSMFilterParam, setGetSMFilterParam] = useState([]);
  const userAuthId = useSelector(selectAuthUserId);
  const { data: userData, isLoading: isProfileDetailsLoading,
    isFetching, refetch: refetchProfileDetails
  } = useUserDetailsByIdQuery({ userId: userAuthId }, { skip: !userAuthId });
  const [updated, setUpdated] = useState("filterEnded");
  const getUserSubmittedFeeds = useSelector(getUserSubmittedFeed);
  const { sMediaMgr: sMMeta, sMIdKeyMap: sMIdKeyMap, feedListModifiers, authUserData: authUsrData } = useSelector(getFeedListMetaData);

  const { data: dDataA, error: dErrA, isLoading: dWaitA, isSuccess: dOKA, isFetching: dDataFetching, refetch: refetchFeedQuery } = useOrgFeedQuery(feedQuery, { refetchOnMountOrArgChange: true }); //, skip: tempSkipOrgFeed });

  useEffect(() => {
    if (authUsrData && page && feedListModifiers)
      if (validateObj(feedListModifiers)) {
        const prepareParams = {
          page,
          orgId: authUsrData?.orgId,
          orgGroupIds: feedListModifiers?.selGroups || [],
          filterSMIds: getSMFilterParam,
        };

        if (feedListModifiers?.selGroupsfilterSMIds) {
          prepareParams["filterSMIds"] = feedListModifiers?.selGroupsfilterSMIds;
        }
        setFeedQuery(prepareParams);
        if (updated === "filterStarted") {
          setUpdated("filterLoading");
        }
      }
  }, [authUsrData, feedListModifiers, page]);

  useEffect(() => {
    try {
      if (dOKA && dDataA) {
        const profileInfo = {};
        dDataA.snetwork_properties.forEach(
          // Note: feedData.snetwork_properties.snetworkplatform_id is nullfor some
          (profile) => (profileInfo[profile.id] = profile)
        );

        if (!dDataFetching) {
          if (validateObj(dDataA)) {
            if (feedData?.length !== dDataA.pagination?.total) {
              let dataObject = {};
              [...dDataA.feed].forEach((item) => {
                let objPost = {
                  ...item,
                  profileInfo: item.snetworkproperty_id && JSON.parse(JSON.stringify(profileInfo[item.snetworkproperty_id])),
                  isUserFeed: false,
                };
                dataObject[item.id] = objPost;
              });
              //let userFData = getUserSubmittedFeeds ? [...getUserSubmittedFeeds] : [];
              let userFData = [];//getUserSubmittedFeeds ? [...getUserSubmittedFeeds] : [];
              const tempFeedData = [...userFData, ...feedData, ...Object.values(dataObject)];

              const uniqueIds = [];
              let uniqueFeedData,
                tempSkipAccPost = 10;
              // TODO: Temp Fix
              if (tempSkipAccPost) {
                let tempFbConn;
                if (sMMeta?.fb?.activTokenId && sMMeta?.fb?.sMTokens && sMMeta?.fb?.sMTokens?.email === "demou3456@gmail.com") {
                  tempFbConn = sMMeta.fb.sMTokens.email;
                }

                uniqueFeedData = tempFeedData.filter((element) => {
                  const isDuplicate = uniqueIds.includes(element?.id);
                  if (!isDuplicate) {
                    let skipAccName = element?.profileInfo?.name;
                    skipAccName = skipAccName?.first ?? skipAccName;
                    skipAccName = skipAccName?.toString().toLowerCase() || "";
                    skipAccName = skipAccName.startsWith("rupel") || skipAccName.startsWith("sachin") ? skipAccName : null;

                    if (!skipAccName || (tempFbConn && skipAccName)) {
                      if (element?.id) {
                        uniqueIds.push(element.id);
                        return true;
                      } else {
                        return false;
                      }
                    }
                    return false;
                  }
                  return false;
                });
              } else {
                uniqueFeedData = tempFeedData.filter((element) => {
                  const isDuplicate = uniqueIds.includes(element.id);
                  if (!isDuplicate) {
                    uniqueIds.push(element.id);
                    return true;
                  }
                  return false;
                });
              }
              setFeedData(uniqueFeedData);

              setPaginationData(dDataA.pagination);
              setIsFetchingData(true);
              setInitialiLoad(false);
            }
          } else {
          }
        }
      }
    } catch (e) {
      console.error("FeedList.jsx:135e:", e);
    }
  }, [dOKA, dDataA, getUserSubmittedFeeds]);

  const theme = useTheme();
  const [uFeedFilter, setUFeedFilter] = useState(null);

  return feedData.length || !initialiLoad ? (
    <PageContainer title={title}>
      <>
        <Grid
          container
          sx={{
            margin: { md: "auto" },
            [theme.breakpoints.up("md")]: { margin: "15px auto auto auto" },
          }}
          maxWidth={"991px"}
          spacing={1.5}
          textAlign="center"
          className="AnalaticsCardMain"
        >
          <Grid item xs={12}>
            <AnalyticsCard theme={theme} selfLeadData={selfLeadApiData}
              leaderboardUserData={leaderboardUserData}
              userData={userData?.users[0]}
              title={"Hello" + userData?.users[0].first_name + "! Here's how you fared this week."} rank="3" shares="13" total_points="4310"
            />
          </Grid>
        </Grid>
        <Grid container maxWidth={"991px"} spacing={2} sx={{ margin: { md: "auto" } }}>
          <StyledCenterGrid item xs={12} lg={8} key={"fLCtn"}>
            <Box md={12}
              display={{ base: "block", md: "flex" }} spacing={2}
              marginTop={{ xs: "0.6rem", md: 0 }} marginBottom="15px"
            //style={{ display: "none" }}
            >
              <FeedMenuList setUpdated={setUpdated} updated={updated} theme={theme} setPage={setPage} setGetSMFilterParam={setGetSMFilterParam} />
            </Box>
            <FeedList
              updated={updated}
              setUpdated={setUpdated}
              theme={theme}
              page={page}
              setPage={setPage}
              highlightPosts={highlightPosts}
              refetchLeaderboardUserData={refetchLeaderboardUserData}
              getSMFilterParam={getSMFilterParam}
              isUserList={false}
              feedQuery={feedQuery}
              setFeedQuery={setFeedQuery}
              dDataA={dDataA}
              dErrA={dErrA}
              dWaitA={dWaitA}
              dOKA={dOKA}
              dDataFetching={dDataFetching}
              refetchFeedQuery={refetchFeedQuery}
              feedData={feedData}
              setFeedData={setFeedData}
              paginationData={paginationData}
              isFetchingData={isFetchingData}
              setIsFetchingData={setIsFetchingData}
            />
            <Grid item marginY="1rem">
              <SubmitIdeaCard theme={theme} />
            </Grid>
          </StyledCenterGrid>
          <StyledCenterGrid
            sx={{
              display: {
                xs: "none",
                lg: "block",
                top: "75px",
                height: "fit-content",
              },
            }}
            xs={12}
            item
            lg={4}
          >
            <Grid item xs={12} sx={{ overflow: "hidden" }}>
              <StyledAnalyticssliderCard sx={{ overflow: "hidden", margin: "0px", width: "100%", marginTop: "13px" }}>
                <Box sx={{ width: "100%" }}>
                  <Slider {...settings} arrows={false} styled={{ width: "100%" }}>
                    <GetCareBanner theme={theme} />
                    <BookAppointmentBanner theme={theme} />
                    <TeleHealthAndPersonCare theme={theme} />
                  </Slider>
                </Box>
              </StyledAnalyticssliderCard>
            </Grid>
            <Grid
              sx={{
                height: "fit-content",
                background: "#fff",
                borderRadius: "6px",
                padding: "16px 0px",
                marginTop: "20px",
              }}
            >
              <SMediaWidget theme={theme} isUserList={true} uFeedFilter={uFeedFilter} setUFeedFilter={setUFeedFilter} />
            </Grid>
            <Grid
              sx={{
                marginTop: "20px",
                backgroundColor: "#fff",
                borderRadius: "2px",
                padding: "0px",
                boxShadow: "0px 0px 1px black",
              }}
              className="slide-main"
            >
              <UsersFeedList theme={theme} page={page} setPage={setPage} highlightPosts={highlightPosts} setHighlightPosts={setHighlightPosts} refetchLeaderboardUserData={refetchLeaderboardUserData} getSMFilterParam={getSMFilterParam} isUserList={true} uFeedFilter={uFeedFilter} />
            </Grid>
            <Grid sx={{ marginTop: "20px" }}>
              <Leaderboard theme={theme} leaderboardUserData={leaderboardUserData} />
            </Grid>

            <Grid item xs={12} sx={{ backgroundColor: "white", marginTop: "20px" }}>
              <ReferAndEarnCard theme={theme} />
            </Grid>
          </StyledCenterGrid>
        </Grid>
      </>
    </PageContainer>
  ) : (
    <LoaderPage />
  );
}
