import { Link } from "react-router-dom";
import {
  Avatar, Box, Grid, Stack, styled,
  // Typography as MuiTypography,
} from "@mui/material";
import { grey } from "@mui/material/colors";
//import LeaderboardOutlinedIcon from "@mui/icons-material/LeaderboardOutlined";
import moment from "moment";
import { HOME_ROUTES } from 'config/routes.config';
import { Button, Card, Typography } from 'core';
//---------------------------------------------------------

const StyledViewAllButton = styled(Button)(({ theme }) => ({
  background: theme.palette.background.paper,
  display: 'flex', justifyContent: 'center',
  alignSelf: 'center', textAlign: 'center',
  fontSize: '1rem', maxWidth: 200, color: grey[600],
  border: '2px solid grey', boxShadow: 'none',
  '&:hover': { background: theme.palette.background.paper, },
}));
//---------------------------------------------------------

function Leaderboard({ theme, leaderboardUserData, isShaddow = true }) {
  //console.log('leaderboardUserData', leaderboardUserData);
  let usrNames = {};

  if (leaderboardUserData && leaderboardUserData?.leaderboard?.length) {
    leaderboardUserData.leaderboard.forEach(lData => {
      let dispName = lData?.user?.name;
      if (dispName.length > 18) {
        dispName = dispName.substring(0, 16) + '..';
        //usrNames[lData.user.id]['dispName'] = dispName;
        //usrNames[lData.user.id] = dispName;
      }
      usrNames[lData.user.id] = dispName;
      //lData.user['dispName'] = dispName;
    });
  }
  const leaderCount = leaderboardUserData?.leaderboard ?
    leaderboardUserData?.leaderboard.length : 0;
  //---------------------------------------------------------

  return (
    <Card
      style={{
        borderRadius: '6px',
        boxShadow: !isShaddow
          ? '0px 0px black'
          : '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
      }}
    >
      <Stack rowGap={3} marginTop="1rem" marginX="1rem"

        alignItems="baseline">
        <Stack
          direction="row"
          spacing={2}
          alignItems="left"
          justifyContent="left"
          alignSelf="left"
        >
          {/*<LeaderboardOutlinedIcon
            sx={{
              width: 40,
              height: 40,
              color: theme.palette.secondary.dark,
            }}
          />*/}
          <img src="/assets/images/bargrowth.png" width="35" height="35" title=""
          //style={{filter: 'grayscale(100%)' }}
          />

          <Box>
            <Typography color_variant="dark" size="medium" fontWeight={600}>
              LEADERBOARD
            </Typography>
            <Typography
              // color_variant="grey[600]"
              size="xsmall"
              // color={theme.palette.secondary.dark}
              sx={{
                color: '#8f9297',
              }}
            >
              Top {leaderCount > 2 ? 3 : ''} positions this week
            </Typography>
          </Box>
        </Stack >
        {leaderboardUserData?.leaderboard &&
          leaderboardUserData?.leaderboard
            .filter((data) => data !== null)
            ?.map((user, key) => {
              return key < 3 ? (
                <Stack
                  spacing={2}
                  direction="row"
                  alignItems="center"
                  justifyContent="center"
                  key={'tLdrs_' + key}
                >
                  <Avatar
                    src={user?.user?.medias?.profile?.url}
                    alt={user?.user?.name}
                    sx={{ height: 60, width: 60, objectFit: 'contain' }}
                  />
                  <Stack>
                    <Typography
                      sx={{
                        color: '#8f9297',
                      }}
                    >
                      {moment.localeData().ordinal(user?.rank) + ' place'}
                    </Typography>
                    <Typography
                      sx={{
                        color: '#8f9297',
                      }}
                      fontWeight={600}
                      size="medium"
                    >
                      {usrNames[user?.user?.id] || user?.user?.name}
                    </Typography>
                    <Typography
                      sx={{
                        color: '#8f9297',
                      }}
                    >
                      {user?.points > 1 ? "Total Coins" : "Total Coin"}
                    </Typography>
                  </Stack>
                </Stack>
              ) : null;
            })}
        <StyledViewAllButton
          component={Link}
          to={HOME_ROUTES?.Leaderboard?.url}
          size="small"
        >
          View All
        </StyledViewAllButton>
      </Stack>
    </Card>
  );
}

export default Leaderboard;
